/* @import 'antd/dist/antd.css'; */

.viewport-fullheight {
  min-height: 100vh;
}

.fullheight {
  min-height: 100%;
}

.nav {
  min-height: 100%;
  text-shadow: 1px 1px lightgray;
}

.login-form {
  margin: 25vh auto auto auto;
  max-width: 34ch;
}

.content-panel {
  margin: 1ex;
}

.top-banner {
  font-weight: bold;
  text-align: center;
  text-shadow: 1px 1px lightgray;
}

.username {
  font-weight: normal;
  font-size: small;
  line-height: 2;
  float: right;
  border-left: 1px dashed #e8e8e8;
  padding-top: 0.5ex;
  padding-left: 2em;
}

.username > button {
  height: 16px;
  font-size: small;
}

.ant-layout-header {
  background: #e6f7ff;
  border-bottom: 1px solid #e8e8e8;
}

.left-border {
  border-left: 1px solid rgb(232,232,232);
}

.double-left-border {
  border-left: 3px double rgb(222,222,222);
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
}

.custom-filter-dropdown input {
  width: 150px;
}

.float-right {
  float: right;
}

.nobreak {
  white-space: nowrap;
}

.ant-table-header-column {
  text-shadow: 1px 1px lightgray;
}

.ant-table-tbody > tr > td {
  background-color: #f0f2f5;
}
.ant-table-fixed > .ant-table-thead > tr > th {
  background-color: #f0f2f5 !important;
}
